import * as _type2 from "es-errors/type";
var _type = _type2;
try {
  if ("default" in _type2) _type = _type2.default;
} catch (e) {}
import * as _objectInspect2 from "object-inspect";
var _objectInspect = _objectInspect2;
try {
  if ("default" in _objectInspect2) _objectInspect = _objectInspect2.default;
} catch (e) {}
import * as _sideChannelList2 from "side-channel-list";
var _sideChannelList = _sideChannelList2;
try {
  if ("default" in _sideChannelList2) _sideChannelList = _sideChannelList2.default;
} catch (e) {}
import * as _sideChannelMap2 from "side-channel-map";
var _sideChannelMap = _sideChannelMap2;
try {
  if ("default" in _sideChannelMap2) _sideChannelMap = _sideChannelMap2.default;
} catch (e) {}
import * as _sideChannelWeakmap2 from "side-channel-weakmap";
var _sideChannelWeakmap = _sideChannelWeakmap2;
try {
  if ("default" in _sideChannelWeakmap2) _sideChannelWeakmap = _sideChannelWeakmap2.default;
} catch (e) {}
var exports = {};
var $TypeError = _type;
var inspect = _objectInspect;
var getSideChannelList = _sideChannelList;
var getSideChannelMap = _sideChannelMap;
var getSideChannelWeakMap = _sideChannelWeakmap;
var makeChannel = getSideChannelWeakMap || getSideChannelMap || getSideChannelList;

/** @type {import('.')} */
exports = function getSideChannel() {
  /** @typedef {ReturnType<typeof getSideChannel>} Channel */

  /** @type {Channel | undefined} */var $channelData;

  /** @type {Channel} */
  var channel = {
    assert: function (key) {
      if (!channel.has(key)) {
        throw new $TypeError("Side channel does not contain " + inspect(key));
      }
    },
    "delete": function (key) {
      return !!$channelData && $channelData["delete"](key);
    },
    get: function (key) {
      return $channelData && $channelData.get(key);
    },
    has: function (key) {
      return !!$channelData && $channelData.has(key);
    },
    set: function (key, value) {
      if (!$channelData) {
        $channelData = makeChannel();
      }
      $channelData.set(key, value);
    }
  };
  // @ts-expect-error TODO: figure out why this is erroring
  return channel;
};
export default exports;